const fontSizeBase = 14
const fontSizeMobile = 12

const px2rem = (size, base = fontSizeBase) => {
  return `${size / base}rem`
}

export default {
  global: {
    fontSizeBase,
    fontSizeMobile,
    minimalViewport: 359,
    maximalViewport: 1200,
    duration: '200ms',
    underlineOffset: '0.5rem',
    media: {
      mobile: 768,
      desktop: 1200,
    },
    containerMaxWidths: {
      desktop: '100rem',
      tablet: '100rem',
      mobile: px2rem(720, fontSizeMobile),
    },
  },
  colors: {
    primary: '#e02b36',
    secondary: '#2f2e2e',
    text: '#1A1A1A',
    textInverted: '#FFFFFF',
    background: '#ffffff',
    white: '#ffffff',
    dark: '#262525',
    gray: '#707070',
    border: '#f1f1f1',
    lightGray: '#f5f5f5',
    muted: '#302e2e',
    gray89: '#e3e3e3',
    gray98: '#fafafa',
    lotionGray: '#fcfcfc',
    silver: '#b6b6b6',
    green: '#5ce02b',
  },
  components: {
    typography: {
      fonts: {
        heading: 'Montserrat',
        text: 'Montserrat',
      },
      variants: {
        h1: {
          mobile: '2rem',
          desktop: '2.5rem',
        },
        h2: '1.875rem',
        h3: '1.625rem',
        h4: '1.5rem',
        h5: '1.375rem',
        h6: '1.25rem',
        p: '0.875rem',
        div: '1rem',
        a: {
          small: '0.875rem',
          normal: '1rem',
          large: '2.75rem',
        },
        span: '1rem',
      },
      weight: {
        light: '300',
        medium: '500',
        semiBold: '600',
        bold: 'bold',
      },
    },
  },
}
