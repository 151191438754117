
import PropTypes from 'prop-types'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'
import GlobalStyles from './globalStyles'
import theme from './theme'


const ThemeProvider = ({children, ...props}) => (
  <StyledThemeProvider theme={theme} {...props}>
    <GlobalStyles />
    {children}
  </StyledThemeProvider>
)

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ThemeProvider
