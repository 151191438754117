import {useCallback, useState} from 'react'
import {isFunction} from 'lodash-es'
import * as storage from '../storage'


const loadState = (key) => {
  try {
    return storage.get(window.localStorage, key)
  } catch (error) {
    return null
  }
}

const storeState = (key, value) => {
  storage.set(window.localStorage, key, value)
  return value
}

const useStoredState = (initialValue, key) => {
  const [state, setState] = useState(loadState(key) || initialValue)

  const setStoredState = useCallback((valueOrFunction) => {
    const nextValue = isFunction(valueOrFunction) ? valueOrFunction(state) : valueOrFunction
    return setState(storeState(key, nextValue))
  }, [key, state])

  const getStoredState = useCallback(() => loadState(key), [key])

  return [state, setStoredState, getStoredState]
}

export default useStoredState
