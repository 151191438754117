import {createGlobalStyle, css} from 'styled-components'
import styledReset from 'styled-reset'
import {media} from '../utils'
import theme from './theme'


export default createGlobalStyle`
  ${styledReset}

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  *,
  *:focus {
    outline: none;
  }

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  :root {
    font-family: 'Montserrat', sans-serif;
    font-size: ${theme.global.fontSizeBase}px;
    scroll-behavior: smooth;

    ${media.down(theme.global.minimalViewport)(css`
      font-size: ${theme.global.fontSizeMobile / (theme.global.minimalViewport) * 100}vw;
    `)}

    ${media.between(theme.global.minimalViewport, 'mobile')(css`
      font-size: ${theme.global.fontSizeMobile}px;
    `)}

    ${media.up('mobile')(css`
      font-size: 0.9vw;
    `)}

    ${media.up(1440)(css`
      @media only screen and (max-resolution: 96dpi) {
        font-size: 12pt;
      }
    `)}
  }

  body {
    color: ${theme.colors.text};
    font-size: 1rem;
    overflow-X: hidden;
  }

  a {
    text-decoration: inherit;
  }

  button {
    font: inherit;
  }

  svg {
    width: inherit;
  }

  input {
    font: inherit;
  }

  textarea {
    font: inherit;
  }
`
