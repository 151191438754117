import PropTypes from 'prop-types'
import {Helmet} from 'react-helmet'
import {If} from 'babel-plugin-jsx-control-statements'
import {createContext, useCallback, useContext, useEffect, useMemo} from 'react'
import useStoredState from '../../../hooks/useStoredState'


// eslint-disable-next-line no-process-env
const GA = process.env.GOOGLE_ANALYTICS
const STORAGE_KEY = 'consent_preferences'

const ConsentContext = createContext()

export const useConsent = () => {
  return useContext(ConsentContext)
}

export const sendPageView = (location) => {
  if (!GA || !window?.gtag) return
  window.gtag('set', 'page_path', location.pathname)
  window.gtag('event', 'page_view')
}

const updateGoogleConsent = (analytics = false, ads = false) => {
  if (!GA || !window?.gtag) return
  window.gtag('consent', 'update', {
    analytics_storage: analytics ? 'granted' : 'denied', // eslint-disable-line camelcase
    ad_storage: ads ? 'granted' : 'denied', // eslint-disable-line camelcase
  })
}

const GoogleAnalytics = ({children}) => {
  const [preferences, setPreferences] = useStoredState(null, STORAGE_KEY)

  const consent = useCallback((preferences) => {
    updateGoogleConsent(preferences?.optionalCookies)
    setPreferences(preferences)
  }, [updateGoogleConsent, setPreferences])

  useEffect(() => {
    if (preferences) {
      consent(preferences)
    }
  }, [preferences, consent])

  const value = useMemo(() => ({
    preferences,
    consent,
  }), [preferences, consent])

  return (
    <>
      <If condition={GA}>
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA}`} />
          <script>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'analytics_storage': 'denied',
              'ad_storage': 'denied',
            });
            gtag('js', new Date());
            gtag('config', '${GA}');
            `}
          </script>
        </Helmet>
      </If>
      <ConsentContext.Provider value={value}>
        {children}
      </ConsentContext.Provider>
    </>
  )
}

GoogleAnalytics.propTypes = {
  children: PropTypes.node,
}

export default GoogleAnalytics
