import {lazy} from 'react'
import {isNumber, reduce} from 'lodash-es'
import {css} from 'styled-components'
import showdown from 'showdown'
import theme from './theme/theme'


const chooseBreakpoint = (breakpoint) => {
  return isNumber(breakpoint) ? breakpoint : theme.global.media[breakpoint]
}

export const media = ({
  down: (breakpoint) => (styles) => css`
    @media screen and (max-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  up: (breakpoint) => (styles) => css`
    @media screen and (min-width: ${chooseBreakpoint(breakpoint)}px) {
      ${styles}
    }
  `,
  between: (startPoint, endPoint) => (styles) => css`
    @media screen
      and (min-width: ${chooseBreakpoint(startPoint)}px)
      and (max-width: ${chooseBreakpoint(endPoint)}px) {
      ${styles}
    }
  `,
})

export const isExternal = (to) => {
  if (typeof window !== 'undefined' && to) {
    const currentHost = window.location.host
    const linkHostMatch = to.match(/:\/\/(.[^/]+)/)

    if (!linkHostMatch) return false
    const [, linkHost] = linkHostMatch

    return currentHost !== linkHost
  }

  return true
}


const waitForLoad = () => (new Promise((resolve) => {
  if (typeof window === 'undefined') return

  const handlePageLoad = (e) => {
    if (e) window.removeEventListener('load', handlePageLoad)
    if ('requestIdleCallback' in window) {
      const callback = (deadline) => {
        if (!deadline.timeRemaining() > 1000) return window.requestIdleCallback(callback)
        resolve()
      }
      window.requestIdleCallback(callback)
    } else {
      resolve()
    }
  }

  if (document.readyState === 'complete') {
    handlePageLoad()
  } else {
    window.addEventListener('load', handlePageLoad)
  }
}))

export const lazyAfterLoad = (component) => lazy(async () => {
  await waitForLoad()
  return component()
})

export const convertMDContent = (content) => {
  const converter = new showdown.Converter({noHeaderId: true})
  const convertedContent = converter.makeHtml(content)

  return convertedContent
}

export const encode = (data) => {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

export const sendForm = async (body) => {
  return await fetch('/', {
    method: 'POST',
    body: encode(body),
  })
}

export const getClosest = (values, value) => reduce(values, (prev, curr) => {
  return (Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev)
})
