import ThemeProvider from './src/theme/ThemeProvider'
import GoogleFont from './src/components/containers/GoogleFont'
import GoogleAnalytics, {sendPageView} from './src/components/containers/GoogleAnalytics'


export const wrapPageElement = ({element}) => (
  <ThemeProvider>
    {element}
  </ThemeProvider>
)

export const wrapRootElement = ({element}) => (
  <GoogleAnalytics>
    <GoogleFont query="family=Montserrat:wght@300;400;600&display=swap" />
    {element}
  </GoogleAnalytics>
)

export const onRouteUpdate = ({location}) => {
  sendPageView(location)
}
